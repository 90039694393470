import React, { useRef, useState, useEffect } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Link, useIntl } from "gatsby-plugin-intl"
import { motion, AnimatePresence } from "framer-motion"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { window } from "browser-monads"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Icons
import UserIcon from "../assets/icons/user-alt.svg"
import DashboardIcon from "../assets/icons/dashboard.svg"
import GlobeIcon from "../assets/icons/global-curriencies.svg"

// Hooks
import useTranslation from "../hooks/useTranslation"

// Icons
import ArrowRightIcon from "../assets/icons/arrow-right.svg"

// Logos
import VisaLogo from "../assets/logos/visa-white.svg"
import MastercardLogo from "../assets/logos/mastercard-white.svg"
import PCILogo from "../assets/logos/pcidss.svg"
import WooLogo from "../assets/logos/woo.svg"
import ShopifyLogo from "../assets/logos/shopify.svg"
import PrestashopLogo from "../assets/logos/prestashop.svg"
import OpencartLogo from "../assets/logos/opencart-full.svg"
import MagentoLogo from "../assets/logos/magento-full.svg"
import OscommerceLogo from "../assets/logos/oscommerce-full.png"
import WhmcsLogo from "../assets/logos/whmcs.jpg"

// Shapes
import TwistedShapesLeft from "../assets/modules-twisted-shapes-left.svg"
import TwistedShapesRight from "../assets/modules-twisted-shapes-right.svg"
import Shape1 from "../assets/modules-shape-1.svg"
import Shape2 from "../assets/modules-shape-2.svg"
import Shape3 from "../assets/modules-shape-3.svg"
import Shape4 from "../assets/modules-shape-4.svg"
import TwistedShape from "../assets/modules-twisted-shape.svg"

// Styles
import "../styles/pages/integrations.scss"

const FloatingWidget = () => {
  const { t } = useTranslation()
  const { formatMessage } = useIntl()
  const [topValue, setTopValue] = useState(170)
  const [positionValue, setPositionValue] = useState("fixed")
  const handleHeightResize = () => {
    if (window.innerHeight < 960) {
      setPositionValue("absolute")
      setTopValue(115)
    } else setPositionValue("fixed")
  }
  useEffect(() => {
    window.addEventListener("resize", handleHeightResize)
    return () => window.removeEventListener("resize", handleHeightResize)
  }, [])
  useEffect(() => {
    handleHeightResize()
  }, [])
  const modules = [
    {
      logo: ShopifyLogo,
      title: "Shopify",
      link: "/payment-modules/shopify",
    },
    {
      logo: WooLogo,
      title: "WooCommerce",
      link: "/payment-modules/woocommerce",
    },
    {
      logo: PrestashopLogo,
      title: "PrestaShop",
      link: "/payment-modules/prestashop",
    },
  ]
  const otherModules = [
    { logo: MagentoLogo, height: 28 },
    { logo: OpencartLogo, height: 24 },
    { logo: OscommerceLogo, height: 24 },
    { logo: WhmcsLogo, height: 19 },
  ]
  useScrollPosition(
    ({ currPos: { y } }) => {
      if (positionValue === "fixed") {
        if (y < -85) setTopValue(64)
        else setTopValue(147 + y)
      }
    },
    [positionValue]
  )

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="container is-hidden-mobile"
    >
      <div className="widget-wrapper">
        <div className="shapes-wrapper">
          <img
            className="shape shape-1 is-hidden-touch is-hidden-desktop-only is-hidden-widescreen-only"
            src={Shape1}
            alt="Shape"
          />

          {positionValue === "fixed" && (
            <img
              className="shape shape-3 is-hidden-touch is-hidden-desktop-only is-hidden-widescreen-only"
              src={Shape3}
              alt="Shape"
            />
          )}
          <img
            className="shape shape-4 is-hidden-touch is-hidden-desktop-only is-hidden-widescreen-only"
            src={Shape4}
            alt="Shape"
          />
        </div>
        <div
          className="content-wrapper"
          style={{ top: topValue, position: positionValue }}
        >
          <div className="widget-title is-flex is-vcentered">
            <h6 className="has-text-white title">
              {t("payment-modules_widget_title")}
            </h6>
            <img className="shape shape-2" src={Shape2} alt="Shape" />
          </div>
          <div className="content-area">
            <div className="has-margin-bottom-5">
              <h6 className="title">{t("payment-modules_widget_subtitle1")}</h6>
              {modules.map(module =>
                module.exlink ? (
                  <a
                    key={module.title}
                    href={module.exlink}
                    className="is-flex is-hspaced is-vcentered module-wrapper"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <span className="is-flex link-text is-vcentered">
                      <img
                        width="40"
                        src={module.logo}
                        alt={module.title}
                        className="has-margin-right-4"
                      />
                      {module.title}
                    </span>
                    <span className="icon arrow-icon">
                      <ArrowRightIcon />
                    </span>
                  </a>
                ) : (
                  <Link
                    key={module.title}
                    to={module.link}
                    className="is-flex is-hspaced is-vcentered module-wrapper"
                  >
                    <span className="is-flex link-text is-vcentered">
                      <img
                        width="40"
                        src={module.logo}
                        alt={module.title}
                        className="has-margin-right-4"
                      />
                      {module.title}
                    </span>
                    <span className="icon arrow-icon">
                      <ArrowRightIcon />
                    </span>
                  </Link>
                )
              )}
            </div>
            <div>
              <h6 className="title">{t("payment-modules_widget_subtitle2")}</h6>
              <p className="has-margin-bottom-5">
                {formatMessage(
                  {
                    id: "payment-modules_widget_text",
                  },
                  {
                    talk: (
                      <Link to="/contact" className="has-text-primary">
                        {t("payment-modules_widget_text_link")}
                      </Link>
                    ),
                  }
                )}
              </p>
              <div style={{ flexWrap: "wrap" }} className="is-flex is-hspaced">
                {otherModules.map(module => (
                  <Link
                    key={module.logo}
                    to="/contact"
                    className="has-margin-bottom-4"
                  >
                    <img
                      style={{ height: module.height }}
                      src={module.logo}
                      alt="Logo"
                    />
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

const FixedWidget = () => {
  const { t } = useTranslation()
  const { formatMessage } = useIntl()
  const modules = [
    {
      logo: ShopifyLogo,
      title: "Shopify",
      link: "/payment-modules/shopify",
    },
    {
      logo: WooLogo,
      title: "WooCommerce",
      link: "/payment-modules/woocommerce",
    },
    {
      logo: PrestashopLogo,
      title: "PrestaShop",
      link: "/payment-modules/prestashop",
    },
  ]
  const otherModules = [
    { logo: MagentoLogo, height: 28 },
    { logo: OpencartLogo, height: 24 },
    { logo: OscommerceLogo, height: 24 },
    { logo: WhmcsLogo, height: 19 },
  ]
  return (
    <div className="container is-hidden-tablet">
      <div className="widget-wrapper is-mobile">
        <div className="content-wrapper">
          <div className="widget-title is-flex is-vcentered">
            <h6 className="has-text-white title">
              {t("payment-modules_widget_title")}
            </h6>
            <img className="shape shape-2" src={Shape2} alt="Shape" />
          </div>
          <div className="content-area">
            <div className="has-margin-bottom-5">
              <h6 className="title">{t("payment-modules_widget_subtitle1")}</h6>
              {modules.map(module =>
                module.exlink ? (
                  <a
                    key={module.title}
                    href={module.exlink}
                    className="is-flex is-hspaced is-vcentered module-wrapper"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <span className="is-flex link-text is-vcentered">
                      <img
                        width="40"
                        src={module.logo}
                        alt={module.title}
                        className="has-margin-right-4"
                      />
                      {module.title}
                    </span>
                    <span className="icon arrow-icon">
                      <ArrowRightIcon />
                    </span>
                  </a>
                ) : (
                  <Link
                    key={module.title}
                    to={module.link}
                    className="is-flex is-hspaced is-vcentered module-wrapper"
                  >
                    <span className="is-flex link-text is-vcentered">
                      <img
                        width="40"
                        src={module.logo}
                        alt={module.title}
                        className="has-margin-right-4"
                      />
                      {module.title}
                    </span>
                    <span className="icon arrow-icon">
                      <ArrowRightIcon />
                    </span>
                  </Link>
                )
              )}
            </div>
            <div>
              <h6 className="title">{t("payment-modules_widget_subtitle2")}</h6>
              <p className="has-margin-bottom-5">
                {formatMessage(
                  {
                    id: "payment-modules_widget_text",
                  },
                  {
                    talk: (
                      <Link to="/contact" className="has-text-primary">
                        {t("payment-modules_widget_text_link")}
                      </Link>
                    ),
                  }
                )}
              </p>
              <div style={{ flexWrap: "wrap" }} className="is-flex is-hspaced">
                {otherModules.map(module => (
                  <Link
                    key={module.logo}
                    to="/contact"
                    className="has-margin-bottom-4"
                  >
                    <img
                      style={{ height: module.height }}
                      src={module.logo}
                      alt="Logo"
                    />
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const HeroSection = () => {
  const { t } = useTranslation()
  return (
    <section className="section is-hero has-margin-bottom-7 has-margin-bottom-0-mobile">
      <div className="container">
        <div className="columns is-variable is-8">
          <div className="column is-6-tablet is-offset-5 is-5-fullhd">
            <div className="shape-wrapper is-hidden-tablet">
              <img className="shape" src={Shape4} alt="Shape" />
            </div>
            <h1 className="title has-margin-bottom-5-mobile ">
              {t("payment-modules_hero_title")}
            </h1>
            <p className="subtitle is-size-6-mobile has-margin-bottom-4-mobile">
              {t("payment-modules_hero_subtitle")}
            </p>
          </div>
        </div>
        <FixedWidget />
      </div>
    </section>
  )
}

const Section2 = () => {
  const { t } = useTranslation()
  const steps = [
    {
      title: t("payment-modules_section2_step1"),
      colorRight: "#f1f9fe",
      colorLeft: "#f1f9fe",
    },
    {
      title: t("payment-modules_section2_step2"),
      colorRight: "#fef9f0",
      colorLeft: "#fcf1dc",
    },
    {
      title: t("payment-modules_section2_step3"),
      colorRight: "#f4fdf3",
      colorLeft: "#e4fae2",
    },
  ]
  const items = [
    {
      icon: <UserIcon />,
      title: t("payment-modules_section2_item1_title"),
      subtitle: t("payment-modules_section2_item1_subtitle"),
    },
    {
      icon: <GlobeIcon />,
      title: t("payment-modules_section2_item2_title"),
      subtitle: t("payment-modules_section2_item2_subtitle"),
    },
    {
      icon: <DashboardIcon />,
      title: t("payment-modules_section2_item3_title"),
      subtitle: t("payment-modules_section2_item3_subtitle"),
    },
  ]
  return (
    <section className="section section-2 has-margin-bottom-7">
      <div className="container">
        <div className="columns">
          <div className="column is-offset-6 is-5">
            <h1 className="title main-title is-spaced has-margin-bottom-3 has-margin-bottom-4-mobile">
              {t("payment-modules_section2_title")}
            </h1>
            <p className="subtitle main-subtitle has-margin-bottom-6 has-margin-bottom-4-mobile">
              {t("payment-modules_section2_subtitle")}
            </p>
          </div>
        </div>

        <div className="columns has-margin-bottom-6">
          <div className="column is-offset-4-tablet is-7-tablet is-offset-4-fullhd is-7-fullhd">
            <div
              style={{
                gridTemplateRows: `repeat(${steps.length}, 1fr)`,
              }}
              className="steps-wrapper "
            >
              {steps.map((step, i) => (
                <React.Fragment key={step.title}>
                  <div
                    style={{
                      gridArea: `${i + 1} / ${i + 1} / ${i + 2} / ${i + 2}`,
                      backgroundImage: `linear-gradient(to left, ${step.colorRight}, ${step.colorLeft})`,
                    }}
                    className="step-wrapper is-flex is-centered-centered is-hidden-touch"
                  >
                    <span className="count is-flex is-size-6 is-centered-centered has-margin-right-4">
                      {i + 1}
                    </span>
                    {step.title}
                    {i !== steps.length - 1 && <span className="arrow" />}
                  </div>
                  <div
                    style={{
                      gridArea: `${i + 1} / ${i + 1} / ${i + 2} / ${i + 5}`,
                      backgroundImage: `linear-gradient(to left, ${step.colorRight}, ${step.colorLeft})`,
                    }}
                    key={step.title}
                    className="step-wrapper is-flex is-centered-centered is-hidden-desktop is-size-6-mobile "
                  >
                    <span className="count is-flex is-size-6 is-size-7-mobile is-centered-centered has-margin-right-4 has-margin-left-4">
                      {i + 1}
                    </span>
                    <p className="has-margin-right-4">{step.title}</p>
                    {i !== steps.length - 1 && <span className="arrow" />}
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column is-offset-5 is-5-fullhd is-6-tablet">
            {items.map(item => (
              <div
                key={item.title}
                className="columns item-wrapper has-margin-bottom-5-mobile"
              >
                <div className="column is-2 icon-wrapper">
                  <span className="icon">{item.icon}</span>
                </div>
                <div className="column">
                  <h3 className="title is-spaced has-margin-bottom-3">
                    {item.title}
                  </h3>
                  <p className="subtitle is-6 is-pre-line ">{item.subtitle}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

const Section3 = ({ imageFluid }) => {
  const { t } = useTranslation()
  const { formatMessage } = useIntl()
  return (
    <section className="section section-3 ">
      <div className="container">
        <div className="twisted-shape-wrapper is-hidden-mobile">
          <img src={TwistedShape} alt="Shape" />
        </div>
        <div className="columns is-centered">
          <div className="column is-4-fullhd">
            <h1 className="title main-title has-text-white has-margin-bottom-4-mobile">
              {t("payment-modules_section3_title")}
            </h1>
            <p className="is-size-6  main-text has-margin-bottom-4">
              {formatMessage(
                {
                  id: "payment-modules_section3_text1",
                },
                {
                  API: (
                    <span className="has-text-green">
                      {t("payment-modules_section3_text1_highlighted")}
                    </span>
                  ),
                }
              )}
            </p>
            <p className="is-size-6 main-text">
              {t("payment-modules_section3_text2")}
            </p>
            <div className="cta-wrapper ">
              <a
                href="http://docs.twispay.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="has-text-green has-text-weight-normal "
              >
                {t("payment-modules_section3_CTA")}

                <span
                  style={{ height: 13, width: 13 }}
                  className="icon  has-margin-left-3"
                >
                  <ArrowRightIcon />
                </span>
              </a>
            </div>
            <div className="is-flex is-vcentered logos-wrapper">
              <img className="has-margin-right-6 pci" src={PCILogo} alt="PCI" />
              <img
                className="has-margin-right-6 visa"
                src={VisaLogo}
                alt="VISA"
              />
              <img
                className="has-margin-right-6 mastercard "
                src={MastercardLogo}
                alt="MASTERCARD"
              />
            </div>
            <div className="img-wrapper is-hidden-tablet">
              <Img className="main-img " fluid={imageFluid} />
            </div>
          </div>
          <div className="column is-offset-1 is-4-fullhd is-hidden-mobile">
            <div className="img-wrapper">
              <Img className="main-img " fluid={imageFluid} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const PaymentModulesPage = ({ data: images }) => {
  const [widgetVisible, setWidgetVisible] = useState(true)
  const { t } = useTranslation()
  const section3Ref = useRef()
  useScrollPosition(
    ({ currPos: { y } }) => {
      if (y < 680) setWidgetVisible(false)
      else setWidgetVisible(true)
    },
    [],
    section3Ref
  )
  return (
    <Layout
      stripeColor="#ebf8ff"
      pageName="payment-modules"
      TwistedShapesLeft={TwistedShapesLeft}
      TwistedShapesRight={TwistedShapesRight}
      hasLightBackground
      noMarginTopFooter
    >
      <SEO title={t("Integrations")} />
      <AnimatePresence>{widgetVisible && <FloatingWidget />}</AnimatePresence>
      <HeroSection />
      <Section2 />
      <div ref={section3Ref}>
        <Section3 imageFluid={images.developer.childImageSharp.fluid} />
      </div>
      <div className="fixed-widget-wrapper has-margin-bottom-4">
        <FixedWidget />
      </div>
    </Layout>
  )
}

export const images = graphql`
  query {
    developer: file(relativePath: { eq: "developer-home.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default PaymentModulesPage
